<template>
  <div class="wrap">
    <div class="card">
      <div class="img">
        <img src="@/assets/payment/failed.gif" />
      </div>
      <h3>Payment Failed</h3>
      <p>Kindly Try Again</p>
      <small>Order ID: {{ $route.params.orderId }}</small>
      {{ this.$route.params.shipmentDocId }}
    </div>
  </div>
</template>

<script>
export default {
  name: "failed",
  mounted() {
    setTimeout(
      () =>
        this.$router.push({
          path: `/myorders/${this.$route.params.shipmentDocId}`,
        }),
      3000
    );
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(235, 95, 95);
}
.card {
  width: 300px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 0px 6px 12px rgba(24, 39, 75, 0.12),
    0px 8px 24px rgba(24, 39, 75, 0.08);
}
h3 {
  color: rgb(63, 0, 0);
}
p {
  color: rgb(57, 57, 57);
}
img {
  width: 200px;
}
</style>
